:root {
  --text-color: #fff;
  --text-accent-color: darksalmon;
  --link-color: darkorange;
  --bg-1: #2c3e50;
  --bg-2: #3498db;
  --bg-1-social: #f3a183;
  --bg-2-social: #ec6f66;
  --username-color: cadetblue;
  --terminal-bg: rgba(56, 4, 40, .9);
  --terminal-header-bg: #bbb;
}

body {
  box-sizing: border-box;
  height: 100vh;
  background: var(--bg-1);
  background: -webkit-linear-gradient(to right, var(--bg-1), var(--bg-2) );
  background: linear-gradient(to right, var(--bg-1), var(--bg-2) );
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  display: flex;
}

body.dark-mode {
  --text-accent-color: #ffca85;
  --link-color: burlywood;
  --bg-1: #211f20;
  --bg-2: #292d34;
  --bg-1-social: #414141;
  --bg-2-social: #485461;
  --username-color: #858585;
  --terminal-bg: rgba(0, 0, 0, .9);
  --terminal-header-bg: #585252;
}

body.dark-mode.firework {
  --terminal-bg: rgba(0, 0, 0, .15);
}

ul {
  margin: 0;
}

.terminal {
  resize: both;
  height: 550px;
  width: min(1000px, 90vw);
  position: absolute;
  overflow: hidden;
}

.terminal .terminal__header {
  height: 25px;
  background-color: var(--terminal-header-bg);
  cursor: move;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0 auto;
  padding: 0 8px;
}

.terminal .terminal__header .fake-button {
  height: 10px;
  width: 10px;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 6px;
  left: 6px;
}

.terminal .terminal__header .fake-button.fake-close {
  background-color: #ff3b47;
  border-color: #9d252b;
  left: 6px;
}

.terminal .terminal__header .fake-button.fake-minimize {
  background-color: #ffc100;
  border-color: #9d802c;
  left: 11px;
}

.terminal .terminal__header .fake-button.fake-zoom {
  background-color: #00d742;
  border-color: #049931;
  left: 16px;
}

.terminal .terminal__body {
  background: var(--terminal-bg);
  color: var(--text-color);
  height: calc(100% - 41px);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 8px 16px;
  font-family: Ubuntu Mono, monospace;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 12px 28px rgba(0, 0, 0, .2), 0 2px 4px rgba(0, 0, 0, .1), inset 0 0 0 1px rgba(255, 255, 255, .05);
}

.terminal .terminal__body code, .terminal .terminal__body a {
  color: var(--text-accent-color);
  font-size: 14px;
}

.terminal .terminal__body .terminal__banner {
  color: var(--text-color);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.terminal .terminal__body .terminal__banner .terminal__author {
  text-align: right;
}

.terminal .terminal__body .terminal__line {
  margin-bottom: 8px;
}

.terminal .terminal__body .terminal__line:before {
  content: "AS212022 ~$ ";
  color: var(--username-color);
}

.terminal .terminal__body .terminal__line input[type="text"] {
  color: var(--text-color);
  width: calc(100% - 150px);
  background: none;
  border: none;
  outline: none;
  font-family: Ubuntu Mono, monospace;
  font-size: 15px;
}

.terminal .terminal__body .terminal__response {
  margin: 8px 0 16px;
}

.terminal .terminal__body .terminal__response table {
  width: 100%;
  border: 1px dashed ;
  padding: 4px;
}

.terminal .terminal__body .terminal__response table a {
  color: #ff8c00;
  text-decoration: none;
}

.terminal .terminal__body .terminal__response table thead th {
  color: #5f9ea0;
  border-bottom: 1px solid #fff;
  padding-bottom: 4px;
  font-weight: normal;
}

.terminal .terminal__body .terminal__response table tbody td {
  padding: 4px;
}

.terminal .terminal__body .terminal__response table tbody tr:not(:last-child) td {
  border-bottom: 1px solid #fff;
}

.socials {
  gap: 16px;
  display: flex;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.socials a {
  background: var(--bg-2-social);
  background: -webkit-linear-gradient(to left, var(--bg-1-social), var(--bg-2-social) );
  background: linear-gradient(to left, var(--bg-1-social), var(--bg-2-social) );
  width: 4em;
  height: 4em;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  box-shadow: 0 2px 8px rgba(99, 99, 99, .2);
}

.socials a:hover {
  background: var(--bg-2-social);
  background: -webkit-linear-gradient(to right, var(--bg-1-social), var(--bg-2-social) );
  background: linear-gradient(to right, var(--bg-1-social), var(--bg-2-social) );
  width: 4em;
  height: 4em;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  box-shadow: 0 2px 8px rgba(99, 99, 99, .2);
}

.socials a i {
  color: #fff;
  font-size: 2em;
}

#banner-github {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 880px) {
  .terminal .terminal__body .terminal__banner pre {
    font-size: 10px;
  }
}

@media (max-width: 640px) {
  body {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  canvas {
    z-index: -1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .terminal {
    position: unset;
    width: unset;
    height: unset;
    resize: none;
  }

  .terminal .terminal__body {
    max-width: unset;
    width: 90vw;
    height: 70vh;
  }

  .terminal .terminal__body .terminal__banner pre {
    font-size: 5px;
  }

  .socials {
    bottom: unset;
    right: unset;
    font-size: 13px;
    position: relative;
  }

  #banner-github img {
    width: 100px;
    height: 100px;
  }

  #version {
    font-size: 13px;
    top: 38px;
    right: 38px;
  }
}

.snowflake {
  color: #fff;
  text-shadow: 0 0 5px #000;
  font-family: Arial, sans-serif;
  font-size: 1em;
}

@keyframes snowflakes-fall {
  0% {
    top: -10%;
  }

  100% {
    top: 100%;
  }
}

@keyframes snowflakes-shake {
  0%, 100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(80px);
  }
}

.snowflake {
  z-index: 9999;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
  position: fixed;
  top: -10%;
}

.snowflake:nth-of-type(0) {
  animation-delay: 0s, 0s;
  left: 1%;
}

.snowflake:nth-of-type(1) {
  animation-delay: 1s, 1s;
  left: 10%;
}

.snowflake:nth-of-type(2) {
  animation-delay: 6s, .5s;
  left: 20%;
}

.snowflake:nth-of-type(3) {
  animation-delay: 4s, 2s;
  left: 30%;
}

.snowflake:nth-of-type(4) {
  animation-delay: 2s, 2s;
  left: 40%;
}

.snowflake:nth-of-type(5) {
  animation-delay: 8s, 3s;
  left: 50%;
}

.snowflake:nth-of-type(6) {
  animation-delay: 6s, 2s;
  left: 60%;
}

.snowflake:nth-of-type(7) {
  animation-delay: 2.5s, 1s;
  left: 70%;
}

.snowflake:nth-of-type(8) {
  animation-delay: 1s, 0s;
  left: 80%;
}

.snowflake:nth-of-type(9) {
  animation-delay: 3s, 1.5s;
  left: 90%;
}

.snowflake:nth-of-type(10) {
  animation-delay: 2s, 0s;
  left: 25%;
}

.snowflake:nth-of-type(11) {
  animation-delay: 4s, 2.5s;
  left: 65%;
}

/*# sourceMappingURL=index.7ab9ebc1.css.map */
